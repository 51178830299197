<template>
  <div class="page-edit-cargo-list">
    <b-row class="mb-1">
      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(99, 99, 99, 0.15)'"
          variant="outline-secondary"
          :disabled="showOverlay"
          @click="cargoList"
        >
          <feather-icon
            icon="RefreshCcwIcon"
            class="mr-50"
          />
          <span class="align-middle">AGGIORNA</span>
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-card title="">

          <!-- input search -->
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Cerca</label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Cerca"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>

          <b-overlay
            :show="showOverlay"
            variant="white"
          >
            <!-- table -->
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :rtl="direction"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm }"
              :pagination-options="{
                enabled: false
              }"
              compactMode
            >
              <template
                slot="table-row"
                slot-scope="props"
              >

                <!-- Column: ds_stato -->
                <span v-if="props.column.field === 'ds_stato'">
                  <b-badge :variant="statusVariant(props.row.ds_stato)" class="text-wrap">
                    <span class="text-wrap">{{ props.row.ds_stato }}</span>
                  </b-badge>
                </span>

                <div
                  v-else-if="props.column.field === 'c_carico'"
                  class="text-important"
                >
                  <span class="text-wrap"><strong>{{ props.row.c_carico }}</strong></span>
                </div>

                <div
                  v-else-if="props.column.field === 'ds_piano_carico'"
                  class="text-important"
                >
                  <span class="text-wrap">{{ props.row.ds_piano_carico }}</span>
                </div>

                <div
                  v-else-if="props.column.field === 'dt_partenza'"
                  class="text-important"
                >
                  <span class="text-nowrap">{{
                    new Intl.DateTimeFormat('en-GB').format(Date.parse(props.row.dt_partenza))
                  }}</span>
                </div>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'" class="">
                  <span>
                    <b-button
                      :to="'/configurazione-carichi/edit/' + props.row.c_carico"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      v-b-toggle.sidebar-right
                      variant="secondary"
                      class="btn-icon mb-50 mb-md-0"
                    >
                      <feather-icon icon="Edit2Icon"/>
                    </b-button>
                  </span>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <template #emptystate>
                <div class="text-center">
                  {{ emptyTableStatus === 'loading' ? 'Caricamento...' : 'Nessun carico' }}
                </div>
              </template>

            </vue-good-table>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BCard,
  BOverlay,
  BButton,
  VBTooltip, VBModal, VBToggle,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BRow,
    BCol,
    VueGoodTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BOverlay,
    BButton,
    VBTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      pageLength: 50,
      dir: false,
      emptyTableStatus: 'loading',
      showOverlay: false,
      columns: [
        {
          label: 'Azioni',
          field: 'action',
        },
        {
          label: 'Stato',
          field: 'ds_stato',
          filterOptions: {
            enabled: true,
            placeholder: 'Filtra',
            filterDropdownItems: ['Da configurare', 'In fase di configurazione', 'Configurazione confermata', 'Configurazione conclusa'],
          },
        },
        {
          label: 'Identificativo',
          field: 'c_carico',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca',
          },
        },
        {
          label: 'Descrizione',
          field: 'ds_piano_carico',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca',
          },
        },
        {
          label: 'Percorso',
          field: 'ds_percorso',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca',
          },
        },
        {
          label: 'Km previsti',
          field: 'n_km_stimati',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca',
          },
        },
        {
          label: 'Data partenza',
          field: 'dt_partenza',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca',
          },
        },
        {
          label: 'N° consegne',
          field: 'n_consegne',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca',
          },
        },
        {
          label: 'N° ordini',
          field: 'n_ordini',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca',
          },
        },
        {
          label: 'N° colli',
          field: 'n_colli',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca',
          },
        },
        {
          label: 'N° volume',
          field: 'n_volume',
          filterOptions: {
            enabled: true,
            placeholder: 'Cerca',
          },
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        'Da configurare': 'light-dark',
        'In fase di configurazione': 'light-warning',
        'Configurazione conclusa': 'light-info',
        'Configurazione conclusa con avvisi' : 'light-danger',
        'Configurazione confermata': 'light-success',
        'Configurazione rifiutata' : 'light-danger'
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  methods: {
    cargoList() {
      this.showOverlay = true

      this.$http.get('/cargo/list').then(res => {
        this.rows = res.data.data.cargos

        this.showOverlay = false

        if (!res.data.data.cargos || res.data.data.cargos.length === 0) {
          this.emptyTableStatus = 'empty'
        }
      }).catch(error => {
        this.showOverlay = false
        this.emptyTableStatus = 'empty'
      })
    },
  },
  created() {
    this.cargoList()
  },
}
</script>

<style lang="scss">
@import "@core/scss/core.scss";
@import "~/src/assets/scss/variables/_variables.scss";
@import '~@core/scss/vue/libs/vue-good-table.scss';
</style>
